import React from 'react'
import { graphql, useStaticQuery } from 'gatsby'
import { Box, Flex } from '@chakra-ui/react'
import BackgroundImage from 'gatsby-background-image'

import Container from '../layout/Container'

const MorgantownBanner = props => {
  const data = useStaticQuery(graphql`
    query {
      morgantown: file(relativePath: { eq: "morgantown.jpg" }) {
        childImageSharp {
          fluid(maxWidth: 1500, quality: 80, cropFocus: CENTER) {
            ...GatsbyImageSharpFluid_withWebp
          }
        }
      }
    }
  `)

  return (
    <Box color="white" textAlign="center" width="full">
      <BackgroundImage Tag="div" fluid={data.morgantown.childImageSharp.fluid}>
        <Box bg="blackAlpha.700">
          <Container py={[28, 28, 36]}>
            <Flex flexWrap="wrap" justifyContent="center">
              <Box
                {...props}
                width={['full', 4 / 5, 3 / 4]}
                sx={{ '*': { color: 'white' } }}
              >
                {props.children}
              </Box>
            </Flex>
          </Container>
        </Box>
      </BackgroundImage>
    </Box>
  )
}

export default MorgantownBanner
