import React from 'react'
import { graphql, Link } from 'gatsby'
import BackgroundImage from 'gatsby-background-image'
import { StaticImage } from 'gatsby-plugin-image'
import {
  Flex,
  Box,
  Button,
  Heading,
  SimpleGrid,
  Tabs,
  TabList,
  TabPanels,
  Tab,
  TabPanel,
  Text,
} from '@chakra-ui/react'

import Layout from '../components/layout/Layout'
import Container from '../components/layout/Container'
import Section from '../components/layout/Section'
import SEO from '../components/elements/SEO'
import Hero from '../components/elements/Hero'
import Book from '../components/elements/Book'
import CloudsBanner from '../components/elements/CloudsBanner'
import MorgantownBanner from '../components/elements/MorgantownBanner'
import AirportCarousel from '../components/elements/AirportCarousel'
import arrow from '../images/outline-chevron_right-24px.svg'

const IndexPage = ({ data }) => {
  const page = data.prismicHomepage
  const events = data.allPrismicEvent

  return (
    <Layout>
      <SEO
        meta_title={page.data.meta_title}
        meta_description={page.data.meta_description}
      />
      <CloudsBanner>
        <Heading as="h1">
          Welcome to MGW: <br /> You’ll fly through our airport.
        </Heading>
      </CloudsBanner>

      <Hero>
        <AirportCarousel>
          {page.data.slides.map(({ image, text }) => (
            <BackgroundImage Tag="div" fluid={image.fluid} key={image.url}>
              <Container py={['10em', '12em', '14em']}>
                <Box
                  bg="primary"
                  p={[6, 6, 9]}
                  mx={[6, 6, 9]}
                  color="white"
                  width={['full', 'full', '50%']}
                >
                  <Box
                    dangerouslySetInnerHTML={{
                      __html: text.html,
                    }}
                    sx={{
                      p: {
                        fontSize: ['lg', 'lg', 'xl'],
                        marginBottom: 0,
                        color: 'white',
                      },
                    }}
                  />
                </Box>
              </Container>
            </BackgroundImage>
          ))}
        </AirportCarousel>
      </Hero>

      <Book />

      <Section id="about" width="full" position="relative" overflow="hidden">
        <Box
          bg="blackAlpha.600"
          width="full"
          height="full"
          position="absolute"
          top={0}
          right={0}
          bottom={0}
          left={0}
          zIndex={2}
        />
        <Box
          position="absolute"
          width="full"
          height="full"
          top={0}
          right={0}
          bottom={0}
          left={0}
          zIndex={1}
          sx={{
            '.gatsby-image-wrapper': {
              position: 'initial',
            },
          }}
        >
          <StaticImage
            src="../images/about.jpg"
            alt="airplane arriving at airport"
            layout="fullWidth"
            objectFit="cover"
          />
        </Box>
        <Container position="relative" zIndex={10}>
          <Flex flexWrap="wrap" justifyContent="center">
            <Box
              width={['full', 'full', 3 / 4]}
              px={8}
              mb={12}
              sx={{ p: { fontSize: 'lg', color: 'white' } }}
            >
              <Text
                dangerouslySetInnerHTML={{
                  __html: page.data.content.html,
                }}
                textAlign="center"
                color="white"
              />
            </Box>

            <SimpleGrid
              columns={[1, 1, 2]}
              spacing={9}
              sx={{ '*': { color: 'white' }, a: { textDecoration: 'none' } }}
            >
              <Box bg="blue.700" p={8}>
                <Link to="/airport">
                  <Flex flexWrap="nowrap" alignItems="center">
                    <Box as="h3">Airport Information</Box>
                    <span>
                      <img src={`${arrow}`} alt="arrow" />
                    </span>
                  </Flex>
                  <Text>{page.data.airport_info.text}</Text>
                </Link>
              </Box>

              <Box bg="blue.500" p={8}>
                <Link to="/flights">
                  <Flex flexWrap="nowrap" alignItems="center">
                    <Box as="h3">Flight Information</Box>
                    <span
                      css={`
                        margin: -0.25em 0 0 0.5em;
                      `}
                    >
                      <img src={`${arrow}`} alt="arrow" />
                    </span>
                  </Flex>
                  <Text>{page.data.flight_info.text}</Text>
                </Link>
              </Box>
            </SimpleGrid>
          </Flex>
        </Container>
      </Section>

      <MorgantownBanner>
        <Text fontSize={['xl']} mb={0}>
          MGW serves North Central West Virginia and surrounding areas
        </Text>
        <Heading as="h4" my={0}>
          Only minutes to Downtown Morgantown
        </Heading>
      </MorgantownBanner>

      <Section id="events-insights" bg="gray.50">
        <Container>
          <Flex flexWrap="wrap" mx={-4}>
            <Tabs>
              <TabList>
                <Tab>
                  <Heading as="h2">Events</Heading>
                </Tab>
                <Tab>
                  <Heading as="h2">Insights</Heading>
                </Tab>
              </TabList>

              <TabPanels bg="white">
                <TabPanel p={9}>
                  <SimpleGrid columns={[1, 1, 3]} spacing={9}>
                    {data.allPrismicEvent.edges.map(({ node: event }) => (
                      <Box
                        key={event.id}
                        as="article"
                        p={6}
                        borderColor="gray.100"
                        borderWidth={1}
                      >
                        <p
                          css={`
                            margin-bottom: 0;
                          `}
                        >
                          {event.data.event_date}
                        </p>
                        <Box as="h3">{event.data.title.text}</Box>
                        <Link to={`events/${event.uid}`}>Read More →</Link>
                      </Box>
                    ))}
                    <Box width={'full'} sx={{ a: { textDecoration: 'none' } }}>
                      <Link to="events">
                        <Button variant="solid" as="span">
                          See All Events
                        </Button>
                      </Link>
                    </Box>
                  </SimpleGrid>
                </TabPanel>

                <TabPanel p={9}>
                  <SimpleGrid columns={[1, 1, 3]} spacing={9}>
                    {data.allPrismicPost.edges.map(({ node: post }) => (
                      <Box
                        key={post.id}
                        as="article"
                        p={6}
                        borderColor="gray.100"
                        borderWidth={1}
                      >
                        <p
                          css={`
                            margin-bottom: 0;
                          `}
                        >
                          {post.data.date}
                        </p>
                        <Box as="h3">{post.data.title.text}</Box>
                        <Link to={`insights/${post.uid}`}>Read More →</Link>
                      </Box>
                    ))}
                    <Box width={'full'} sx={{ a: { textDecoration: 'none' } }}>
                      <Link to="insights">
                        <Button variant="solid" as="span">
                          See All Posts
                        </Button>
                      </Link>
                    </Box>
                  </SimpleGrid>
                </TabPanel>
              </TabPanels>
            </Tabs>
          </Flex>
        </Container>
      </Section>
    </Layout>
  )
}

export default IndexPage

export const query = graphql`
  query HomePage {
    prismicHomepage {
      data {
        content {
          html
        }
        airport_info {
          text
        }
        banner {
          banner_text {
            text
          }
        }
        flight_info {
          text
        }
        meta_description
        meta_title
        slides {
          image {
            alt
            url
            fluid(maxWidth: 1500) {
              ...GatsbyPrismicImageFluid
            }
          }
          text {
            html
          }
        }
      }
    }

    allPrismicEvent(
      sort: { fields: data___event_date, order: DESC }
      limit: 3
    ) {
      edges {
        node {
          data {
            content {
              html
            }
            event_date(formatString: "MMM Do, YYYY")
            title {
              text
            }
          }
          id
          uid
        }
      }
    }
    allPrismicPost(sort: { fields: data___date, order: DESC }, limit: 3) {
      edges {
        node {
          data {
            date(formatString: "MMM Do, YYYY")
            content {
              html
            }
            title {
              text
            }
          }
          id
          uid
        }
      }
    }
  }
`
