import React from 'react'
import Carousel from 'nuka-carousel'
import { Box } from '@chakra-ui/react'

import { MdNavigateBefore, MdNavigateNext } from 'react-icons/md'

function AirportCarousel(props) {
  return (
    <Carousel
      easing="easeSinOut"
      transitionMode="scroll"
      speed={300}
      wrapAround
      renderCenterLeftControls={({ previousSlide }) => (
        <Box
          onClick={previousSlide}
          fontSize={['4xl', '4xl', '6xl']}
          color="whiteAlpha.700"
          _hover={{ color: 'white', cursor: 'pointer' }}
          width={16}
        >
          <MdNavigateBefore />
        </Box>
      )}
      renderCenterRightControls={({ nextSlide }) => (
        <Box
          onClick={nextSlide}
          fontSize={['4xl', '4xl', '6xl']}
          color="whiteAlpha.700"
          _hover={{ color: 'white', cursor: 'pointer' }}
          width={16}
        >
          <MdNavigateNext />
        </Box>
      )}
      {...props}
    />
  )
}

export default AirportCarousel
